import React from "react"

import styles from "./content.module.scss"
import { AccountGuard } from "../../../../guards/account.guard"

const ContentPage4 = () => {
  return (
    <AccountGuard>
      <div className={`${styles.header}`}>
        <h2>Technical Official Introductory</h2>
      </div>

      <div className={`${styles.wrapper}`}>
        <div className={`${styles.sectionHeader}`}>
          <h3>Section 04</h3>
          <h1>The Basics</h1>
        </div>
      </div>

      <div className={`${styles.contentWrapper}`}>
        <ul className={`${styles.content}`}>
          <li>
            <p>What does a Technical Official do?</p>

            <p>
              A Technical Officials roles goes beyond counting and giving
              “no-reps.” One must constantly be assessing fast paced and
              sometimes hectic movements with strict criteria in order to ensure
              fair and accurate outcomes.
            </p>

            <p>
              <b>On the Field of Play</b>
            </p>

            <p>
              Reward completions of prescribed standards for a designated task.
              Asking these following questions and fully understanding the
              correct answers will build confidence and allow for a better
              understand on the Technical Official role.
              <ul>
                <li>Task – WHAT DOES THE ATHLETE HAVE TO DO</li>
                <li>
                  Prescribed Standard – HOW DOES THE ATHLETES HAVE TO DO IT
                </li>
                <li>
                  Points of performance – WHAT ARE THE TECHNICAL OFFICIALS
                  REVIEWING
                </li>
                <li>We do not assess form</li>
                <li>We determine completion of the Task only</li>
              </ul>
            </p>

            <p>
              <b>Before, During, and After Events</b>
            </p>

            <p>
              Professional decorum is an expectation of all Technical Officials
              at iF3 events. This extends to the Technical Officials
              interactions with all individuals at a particular event. In
              current world environments, this includes use of social media and
              other digital platforms and what Technical Officials share with
              the general public.
            </p>

            <p>
              <b>Rights of the Technical Official</b>
            </p>

            <p>
              <ul>
                <li>Proper Respect from athletes and teams</li>
              </ul>
            </p>

            <p>
              Technical Officials are ensured the proper respect from athletes,
              teams, and spectators
            </p>

            <p>
              <ul>
                <li>Universal integrity of TO decisions</li>
              </ul>
            </p>

            <p>
              A Technical Official must perform his or her assigned task in good
              faith with the intent to properly execute based on his or her
              knowledge, skills, ability, and training. With this high standard
              in mind, Technical Officials decisions are accepted to be true.
            </p>

            <p>
              Processes exist to properly address scoring errors. If at any
              point it is determined that a Technical Official is acting with
              any less than the highest integrity, that Technical Official may
              be subject to decertification as an iF3 Technical Official.
            </p>
          </li>
        </ul>

        <div className={`${styles.buttons}`}>
          <div className={`${styles.previous}`}>
            <a href="/courses/toi/content/3">
              <i
                className={`fas fa-arrow-alt-circle-left uk-margin-small-right`}
              />
              Previous
            </a>
          </div>
          <div className={`${styles.next}`}>
            <a href="/courses/toi/content/5">
              Next
              <i
                className={`fas fa-arrow-alt-circle-right uk-margin-small-left`}
              />
            </a>
          </div>
        </div>
      </div>
    </AccountGuard>
  )
}

export default ContentPage4
